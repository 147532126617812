<template>
  <div class="has-text-centered is-full-page-layout">
    <div class="is-user-avatar">
      <img :src="userAvatar" :alt="userName" />
    </div>
    <div class="error-page-caption">
      <h1>{{ userName }}</h1>
    </div>
    <div class="error-page-action">
      <div v-if="!isPasswordInputActive" class="buttons is-centered">
        <button type="button" class="button is-primary" @click="passwordActivate">
          Unlock
        </button>
      </div>
      <form @submit.prevent="submit" v-else>
        <b-field position="is-centered">
          <b-input ref="input" type="password" v-model="form.password" />
          <div class="control">
            <button type="submit" class="button is-primary" :class="{ 'is-loading': isLoading }" :disabled="!form.password">
              <b-icon icon="lock-open" custom-size="default" />
            </button>
          </div>
        </b-field>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LockScreen",
  data() {
    return {
      isPasswordInputActive: false,
      isLoading: false,
      form: {
        password: null
      }
    };
  },
  computed: {
    ...mapState(["userName", "userAvatar"])
  },
  methods: {
    passwordActivate() {
      this.isPasswordInputActive = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    submit() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        this.$router.push("/");
      }, 750);
    }
  }
};
</script>
