<template>
  <div class="checkmark_container" v-bind:class="{ show_checkmark: shown }">
    <svg width="50%" height="50%" viewBox="0 0 140 100">
      <path class="checkmark" v-bind:class="{ checked: shown }" d="M10,50 l25,40 l95,-70" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    shown: { type: Boolean }
  }
};
</script>

<style scoped>
/* Checkmark */

.checkmark_container {
  border-radius: 50%;
  position: absolute;
  right: 10px;
  background: #2ecc71;
  width: 50px;
  height: 50px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ease;
  z-index: 1;
  margin-top: 25px;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.checked {
  animation: draw 0.5s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
