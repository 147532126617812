<template>
  <section class="section hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-fifths-tablet is-two-fifths-desktop">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FullPage",
  mounted() {
    this.$store.commit("fullPage", true);
    this.$store.commit("asideRightToggle", false);
  },
  beforeDestroy() {
    this.$store.commit("fullPage", false);
  }
};
</script>

<style scoped>
section {
  background: -webkit-linear-gradient(bottom, #7c4f7a, #472b68);
}
</style>
