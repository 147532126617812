<template>
  <div class="input_container">
    <CheckMark :shown="valid_password" />
    <b-field label="New Password">
      <b-input
        type="password"
        ref="password"
        :value="value"
        @input="v => $emit('input', v)"
        autocomplete="off"
        placeholder="Password"
        required
      />
    </b-field>

    <ul>
      <li v-bind:class="{ is_valid: contains_min_characters }">Minium {{ password_min }} Characters</li>
      <li v-bind:class="{ is_valid: contains_number }">Contains Number</li>
      <li v-bind:class="{ is_valid: contains_uppercase }">Contains Uppercase</li>
      <li v-bind:class="{ is_valid: contains_special_character }">Contains Special Character</li>
    </ul>

    <CheckMark :shown="confirmedOk" />
    <b-field label="Confirm Password" :message="confirmedError" :type="confirmMessageType">
      <b-input type="password" v-model="confirmPassword" autocomplete="off" placeholder="Password again" required />
    </b-field>
  </div>
</template>

<script>
import CheckMark from "@/components/CheckMark";
export default {
  components: { CheckMark },
  props: {
    value: { type: String },
    autofocus: { type: Boolean }
  },
  data() {
    return {
      confirmPassword: "",
      password_length: 0,
      password_min: 6,
      contains_min_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false
    };
  },
  mounted() {
    if (this.autofocus) this.$refs.password.focus();
  },
  computed: {
    confirmedError() {
      return this.confirmPassword && this.value !== this.confirmPassword ? "Password Confirmation does not match" : "";
    },
    confirmedOk() {
      const ok = this.confirmPassword.length > 0 && this.value === this.confirmPassword;
      this.$emit("isValid", this.valid_password && ok);
      return ok;
    },
    confirmMessageType() {
      return this.confirmedError ? "is-danger" : "";
    }
  },

  watch: {
    value(password) {
      this.password_length = password.length;
      const format = /[ !@#£$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      if (this.password_length >= this.password_min) {
        this.contains_min_characters = true;
      } else {
        this.contains_min_characters = false;
      }

      this.contains_number = /\d/.test(password);
      this.contains_uppercase = /[A-Z]/.test(password);
      this.contains_special_character = format.test(password);

      if (
        this.contains_min_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }

      this.$emit("isValid", this.valid_password && this.confirmedOk);
    }
  }
};
</script>

<style scoped>
ul {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

li {
  margin-bottom: 8px;
  color: #525f7f;
  position: relative;
}

li:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

#app {
  width: 400px;
}

/* Password Input --------- */

input[type="password"] {
  line-height: 1.5;
  display: block;
  color: rgba(136, 152, 170, 1);
  font-weight: 300;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  border-radius: 0.25rem;
  background-color: #fff;
  transition: border-color 0.4s ease;
  border: 1px solid #cad1d7;
  outline: 0;
}

input[type="password"]:focus {
  border-color: rgba(50, 151, 211, 0.45);
}

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 100%;
}
</style>
