<template>
  <div>
    <card-component>
      <div class="columns is-flex is-centered">
        <figure class="image xyea-logo">
          <img src="../../assets/xyea-login.svg" alt="logo" />
        </figure>
      </div>
      <hr />

      <form @submit.prevent="submit" method="POST">
        <b-field label="Email">
          <b-input name="email" type="email" icon="email" v-model="form.email" required ref="login" v-focus />
        </b-field>

        <b-field label="Password">
          <b-input type="password" name="password" icon="lock" v-model="form.password" required />
        </b-field>

        <hr />

        <b-field grouped>
          <div class="control">
            <button type="submit" class="button is-primary" :class="{ 'is-loading': isLoading }">
              Login
            </button>
          </div>
          <div class="control">
            <router-link to="/password-recovery" class="button is-outlined is-primary">
              Forgot Password?
            </router-link>
          </div>
        </b-field>
      </form>
    </card-component>

    <error-box :is-active="showError" :message="error" @close="close" />
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import ErrorBox from "@/components/ErrorBox";
import alerts from "../../common/alerts";

export default {
  name: "Login",
  components: { CardComponent, ErrorBox },
  data() {
    return {
      error: null,
      isLoading: false,
      form: {
        email: process.env.VUE_APP_DEFAULT_LOGIN_EMAIL,
        password: process.env.VUE_APP_DEFAULT_LOGIN_PWD
      }
    };
  },
  computed: {
    showError() {
      return !!this.error;
    }
  },
  methods: {
    close() {
      this.error = null;
    },
    async submit() {
      // this.formIsValid = true;
      // if (
      //   this.email === "" ||
      //   !this.email.includes("@") ||
      //   this.password.length < 6
      // ) {
      //   this.formIsValid = false;
      //   return;
      // }

      this.isLoading = true;

      try {
        await this.$store.dispatch("login", this.form);

        // // Instantiate PasswordCredential with the form
        // if (window.PasswordCredential) {
        //   const cred = new window.PasswordCredential({
        //     id: this.form.email,
        //     password: this.form.password
        //     // name: `${user.firstName || ""} ${user.lastName || ""}`.trim(),
        //     // iconURL: user.avatar
        //   });
        //   await navigator.credentials.store(cred);
        // }

        const redirectUrl = this.$route.query.redirect ? "/" + this.$route.query.redirect : this.$store.getters.defaultRoute;
        // possible naviagation guards cause furthe redirect, so  ignore error here
        this.$router.replace(redirectUrl).catch(() => {});
      } catch (e) {
        alerts.showErrorAlert(e, "Error Logging In");
      }

      this.isLoading = false;
    },
    goToPasswordRecovery() {
      this.$router.push({ name: "password-recovery" });
    }
  }
};
</script>

<style scoped>
.xyea-logo {
  width: 75%;
  padding: 0;
}
</style>
