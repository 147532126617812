<template>
  <card-component title="Password reset" :icon="Icons.passwordReset">
    <form @submit.prevent="submit" method="POST">
      <CheckMark :shown="validEmail" />
      <b-field label="E-mail Address" message="E-mail used for registration">
        <b-input ref="email" type="email" name="email" v-model="form.email" required />
      </b-field>
      <PasswordStrength v-model="form.password" @isValid="v => (validPassword = v)" autofocus />

      <hr />

      <b-field grouped>
        <div class="control">
          <button type="submit" class="button is-primary" :class="{ 'is-loading': isLoading }" :disabled="!allValid">Reset Password</button>
        </div>
        <div class="control">
          <router-link to="/login" class="button is-primary is-outlined"> Back </router-link>
        </div>
        <div class="control" v-if="hasError">
          <router-link :to="'/password-recovery?email=' + form.email" class="button is-primary is-outlined">
            Get New Rest Link
          </router-link>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import PasswordStrength from "@/components/PasswordStrength";
import CheckMark from "@/components/CheckMark";
import IconsMixin from "@/mixins/iconsMixin";
import alerts from "../../common/alerts";

export default {
  name: "Login",
  mixins: [IconsMixin],
  components: { CardComponent, PasswordStrength, CheckMark },
  data() {
    return {
      isLoading: false,
      validEmail: false,
      validPassword: false,
      hasError: false,
      form: {
        email: this.$route.query.email,
        password: "",
        code: this.$route.query.code
      }
    };
  },
  computed: {
    allValid() {
      return this.validEmail && this.validPassword;
    }
  },
  watch: {
    "form.email": {
      immediate: true,
      handler(email) {
        // this.$refs.email is not available immediately, so we use next tick, so we can use the html checkValidity
        this.$nextTick(() => {
          this.validEmail = this.$refs.email && this.$refs.email.$el.getElementsByTagName("input")[0].checkValidity();
        });
      }
    }
  },
  methods: {
    async submit() {
      this.isLoading = true;

      await this.$http
        .post("authenticate/resetpassword", this.form)
        .then(r => {
          this.form.email = null;
          this.$buefy.snackbar.open({
            message: "Password Reset Succesfully! You can now login",
            duration: 3000,
            queue: false
          });
          this.$router.push({ name: "login" });
        })
        .catch(e => {
          alerts.showErrorAlert(e, "Error resetting password");
          this.hasError = true;
        })
        .then(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
