<template>
  <card-component title="Forgot Password" :icon="Icons.passwordReset">
    <form @submit.prevent="submit" method="POST">
      <b-field label="E-mail Address" message="E-mail used for registration">
        <b-input type="email" name="email" v-model="form.email" required v-focus />
      </b-field>

      <hr />

      <b-field grouped>
        <div class="control">
          <button type="submit" class="button is-primary" :class="{ 'is-loading': isLoading }">Send Reset Link</button>
        </div>
        <div class="control">
          <router-link to="/login" class="button is-primary is-outlined"> Back </router-link>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import alerts from "../../common/alerts";
import IconsMixin from "@/mixins/iconsMixin";

export default {
  name: "Login",
  components: { CardComponent },
  mixins: [IconsMixin],
  data() {
    return {
      isLoading: false,
      form: {
        email: this.$route.query.email
      }
    };
  },
  methods: {
    async submit() {
      this.isLoading = true;

      await this.$http
        .post("authenticate/forgotpassword", this.form)
        .then(r => {
          this.form.email = null;
          this.$buefy.snackbar.open({
            message: "Password reset link sent, check your inbox",
            duration: 3000,
            queue: false
          });
          this.$router.push({ name: "login" });
        })
        .catch(e => {
          alerts.showErrorAlert(e, "Error sending password reset link");
        })
        .then(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
